module.exports = {
    name: 'Beyond',
    logo: 'https://img.genflow.com/beyond/checkout/logo.png',
    vendorUUID: 'c8ab735e-6ae3-40ee-bdf3-42e39abb6fb7',
    metaData: {
        title: 'Beyond',
        description: 'Take control of your fitness, diet and happiness with Beyond.',
        author: 'GENFLOW',
        url: 'https://www.beyondapp.co.uk/',
        image: 'https://img.genflow.com/beyond/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#8dc8e8',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '463138434842238',
        tagManager: '',
        tapfiliate: '28362-e59a87',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://shop.beyondapp.co.uk/pages/terms-and-conditions',
        privacy: 'https://shop.beyondapp.co.uk/policies/privacy-policy',
        support: 'https://support.beyondapp.co.uk/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/beyond-app/id1547481068',
        android: 'https://play.google.com/store/apps/details?id=com.meggangrubb.beyond',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
        thankYou: {
            additionalText: 'Get motivated with Bluee activewear and an exclusive discount for Beyond subscribers... use code BEYOND10 at checkout on your first order',
            imageUrl: 'https://img.genflow.com/beyond/checkout/thankyou.jpg',
            ctaText: 'Save 10% on Bluee',
            ctaLink: 'https://www.movewithbluee.com/discount/BEYOND10'
        }
    },
    products: [
        {
            title: '1 Month',
            // product: '1month',
            product: '1monthsale', // previous
            // label: '20% OFF',
            // coupon: 'wnRty414',
        }, {
            title: '3 Months',
            // product: '3monthsale',
            product: '3month', // previous
            // label: '25% OFF',
            // coupon: '1xkT2ipL',
        }, {
            title: '12 Months',
            // product: '12monthsale',
            product: '12month', // previous
            // label: '30% OFF',
            // coupon: 'dawfhsPU',
        },
    ],
    saleProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: null,
        }, {
            title: '3 Months',
            product: '3monthsale',
            label: null,
        }, {
            title: '12 Months',
            product: '12monthsale',
            label: null,
        },
    ],
    offers: [
        {
            id: 'leadMagnet',
            products: [
                {
                    title: '1 Month',
                    product: '1monthsale',
                    label: null,
                }, {
                    title: '3 Months',
                    product: '3monthextendedtrial',
                    label: null,
                }, {
                    title: '12 Months',
                    product: '12monthextendedtrial',
                    label: null,
                },
            ],
        },
        {
            id: 'winback',
            products: [
                {
                    title: '1 Month',
                    product: '1monthsale',
                    // coupon: '1K4J7BlE',
                    // label: '50% OFF',
                }, {
                    title: '3 Months',
                    product: '3monthsale',
                    coupon: 'lzd190X1',
                    label: '50% OFF',
                }, {
                    title: '12 Months',
                    product: '12monthsale',
                    coupon: 'lzd190X1',
                    label: '50% OFF',
                },
            ],
        },
        {
            id: 'mumandbabyoffer',
            products: [
                {
                    title: '3 Months',
                    product: 'mumandbabyoffer',
                    // label: '50% OFF',
                },
            ]
        }
    ],
    currencies: {
        available: [
            {
                code: 'GBP',
                icon: '🇬🇧',
            },
            {
                code: 'EUR',
                icon: '🇪🇺',
            },
            {
                code: 'USD',
                icon: '🇺🇸',
            },
        ],
        default: 'GBP',
    },
};
